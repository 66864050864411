import { useAuthStore } from '~/stores/auth'
import { usePageStore } from '~/stores/page'
import { storeToRefs } from 'pinia'

export default defineNuxtRouteMiddleware(async (to, from) => {
   const { isAuthenticated, lastRoute } = storeToRefs(useAuthStore())
   const pageStore = usePageStore()

   if (to?.name !== "login") {
      lastRoute.value = to.path
   }
   
   if (!isAuthenticated.value && to?.name?.toString() !== 'login') {
      abortNavigation()
      useAuthStore().resetStores()
      return navigateTo('/login')
   }
   else if (isAuthenticated.value && to?.name?.toString() === 'login') {
      abortNavigation()
      return navigateTo('/')
   }

   if (pageStore.changesExist && !pageStore.isForceNav) {
      pageStore.isWarningShown = true
      pageStore.tempNavTo = to
      return abortNavigation()
   }
   else {
      pageStore.changesExist = false
      pageStore.isForceNav = false
      pageStore.isWarningShown = false
      pageStore.tempNavTo = null
      if (to.name === 'login') {
         useAuthStore().resetStores()
      }
   }
})